export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: ['480px', '768px', '1025px'],
  fonts: {
    body: 'Poppins, sans-serif',
    heading: 'Poppins, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    thin: 100,
    body: 200,
    heading: 900,
    bold: 700,
  },
  lineHeights: {
    body: 1.625,
    heading: 1.25,
  },
  colors: {
    text: '#f1f1f1',
    background: '#231F1F',
    primary: '#FDC300',
    secondary: '#5A4500',
    muted: '#F4F4F4',
    bg2: '#212121',
    bg3: '#5FA052',
    bgRush: '#B22222',
    secondaryDark: '#303438',
    normal: '#6C79D9',
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
      '&:hover': {
        color: 'red',
      },
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
    select: {
      xs: {
        fontSize: 0,
      },
    },
  },
  links: {
    button: {
      textDecoration: 'none',
      color: 'text',
      display: 'inline-block',
    },
    nav: {
      fontWeight: '500',
      textDecoration: 'none',
      marginLeft: '6px',
      textShadow: '0.5px 0.5px 0 rgba(0,0,0,0.2)',
      transition: 'all 0.2s linear',
      '&:hover': {
        color: 'secondary',
      },
    },
  },
  text: {
    xs: {
      fontSize: 0,
    },
    sm: {
      fontSize: 1,
    },
  },
  buttonLink: {
    textDecoration: 'none',
    display: 'inline-block',
    lineHeight: 1,
    transparent: {
      border: '1px solid primary',
    },
  },
  button: {
    transparent: {
      textDecoration: 'none',
      lineHeight: 1,
      border: '1px solid',
      color: 'primary',
      padding: '10px',
      minWidth: '100px',
      textAlign: 'center',
      transition: 'all ease 0.3s',
      opacity: '0.7',
      backgroundColor: 'rgba(35, 31, 31, 0.5)',
      '&:hover': {
        opacity: '1',
      },
    },
    plain: {
      color: 'text',
      background: 'transparent',
      border: 'none',
    },
  },
  link: {
    color: 'text',
    transition: 'all ease 0.3s',
    textDecoration: 'none',
    '&:hover': {
      color: 'primary',
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  headerText: {
    fontSize: '50px',
    fontWeight: 'bold',
  },
}
