// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sticker-estimate-tsx": () => import("./../../../src/pages/sticker/estimate.tsx" /* webpackChunkName: "component---src-pages-sticker-estimate-tsx" */),
  "component---src-pages-sticker-pricing-tsx": () => import("./../../../src/pages/sticker/pricing.tsx" /* webpackChunkName: "component---src-pages-sticker-pricing-tsx" */),
  "component---src-pages-sticker-samples-tsx": () => import("./../../../src/pages/sticker/samples.tsx" /* webpackChunkName: "component---src-pages-sticker-samples-tsx" */)
}

